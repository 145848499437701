<template>
  <div class="container">

    <div class="col-md-12" v-if="isFirstLoaded">
      <h4 class="card-title" v-if="!isLoaded"><i class="pull-right fa fa-gear fa-spin"></i></h4>

      <p>
        <div v-for="(ticker, index) in validated" :key="index" class="pull-right">
        <n-button @click="gotoTicker(ticker.tickers[0])" type="outline-primary" size="sm"><small>{{ ticker.name }}
            <br><b>{{ ticker.tickers[0] }}</b></small></n-button>
        </div>
      <h4> Valid </h4>

      </p>
      <span  class="pull-right">
          <a :href="getDebugApiEntry(apiCall)" target="_blank">
            &nbsp;&nbsp;<i class="fa fa-gear"></i> OPEN API</a>
        </span>
      <hr class="clear">
      <p>
      <h4> Invalid </h4>
      <b><div v-for="(text, index) in unvalidated" :key="index">
        {{ index }} ::  {{ text }}
      </div></b><br>
      
    </p>
    </div>
  </div>
</template>

<script>
import globalConfig from "@/globalConfig.js";
import globalController from "@/globalController.js";
import utils from "@/globalUtil.js";
import { mapState, mapActions } from "vuex";

import {
  VnudCard,
  Table as NTable
} from "@/components";

export default {
  props: {
    newsId: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  components: { VnudCard, NTable, },
  created() {
    console.log(" Component creation ")
    this.loadData(this.watchlist);
  },
  
  computed: {
    ...mapState({
      user: (state) => state.user,
      logged_in: (state) => state.logged_in,
      highlight_search: (state) => state.highlight_search,
    }),
  },
  data() {
    return {
      validated: null,
      unvalidated: null,
      response: null,
      isLoaded: false,
      isFirstLoaded: false,
      apiCall: "",
    };
  },
  mounted() {
    this.loadData()
  },
  methods: {
    getDebugApiEntry(apiCall) {
      return globalConfig.getApiPath(apiCall);
    },
    gotoTicker(ticker) {
      this.$router.push(globalController.get_route_ticker(ticker));
    },
    async loadData(newsId) {
      this.isLoaded = false;
      this.apiCall = "/news/test/tickers/" + this.newsId
      globalController.api_call(
        this.apiCall,
        (result) => {
          console.log("Loaded result " + result);

          this.isLoaded = true;
          this.isFirstLoaded = true;
          this.response = result.response;
          this.validated = result.validated_tickers;
          this.unvalidated = result.unvalidated_tickers;
        },
        (error) => {
          console.log("Failed loading: Error loading watchlist");
          utils.runToast(
            ` ${error} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        }
      );
    },
  },
}
</script>


<style scoped>
.icon_position {
  float: right;
  margin-left: 10px;
}

.action_button {
  margin-right: 10px;
}
</style>
