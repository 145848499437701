<template>
  <div class="terms-page">

    <nav class="navbar">
      <div class="nav-brand">
        <a class="navbar-brand" href="/" style="margin-left: 5px; max-width:175px;">
            <div class="logo-tothemoon noselect">
            <div class="logo-small-text">HEADING TO</div>
            <div class="logo-large-text"><i class='fal fa-rocket fa-xs'></i> MARS</div>
            <div class="logo-small-life">.COM</div>
            </div>
        </a>
      </div>
      <div class="nav-links">
        <router-link to="/contact" class="nav-link">Contact</router-link>
        <router-link to="/login" class="nav-link login-btn">Login</router-link>
      </div>
    </nav>
    <div class="terms-container">
      <h1>Terms of Service</h1>
      <p class="last-updated">Last updated: March 2024</p>

      <section>
        <h2>1. Agreement to Terms</h2>
        <p>By accessing and using HeadingToMars.com ("the Platform"), you agree to be bound by these Terms of Service. If you disagree with any part of these terms, you may not access the Platform.</p>
      </section>

      <section>
        <h2>2. Description of Service</h2>
        <p>HeadingToMars.com provides AI-powered investment analytics and portfolio management tools. Our services include but are not limited to:</p>
        <ul>
          <li>Financial data analysis and visualization</li>
          <li>Portfolio tracking and optimization</li>
          <li>Investment research and recommendations</li>
          <li>Market analysis and insights</li>
        </ul>
      </section>

      <section>
        <h2>3. User Accounts</h2>
        <p>When you create an account with us, you must provide accurate, complete, and current information. You are responsible for safeguarding your account credentials and for any activities under your account.</p>
        <p>You agree to notify us immediately of any unauthorized use of your account or any other breach of security.</p>
      </section>

      <section>
        <h2>4. Financial Disclaimer</h2>
        <p>The information provided through our Platform is for informational purposes only and should not be considered as financial advice. We are not licensed financial advisors, and you should consult with qualified professionals before making investment decisions.</p>
        <p>Past performance is not indicative of future results. All investments carry risk, and you may lose money.</p>
      </section>

      <section>
        <h2>5. User Responsibilities</h2>
        <p>You agree not to:</p>
        <ul>
          <li>Use the Platform for any illegal purpose</li>
          <li>Attempt to gain unauthorized access to any part of the Platform</li>
          <li>Interfere with or disrupt the Platform's operation</li>
          <li>Share your account credentials with others</li>
          <li>Use the Platform to transmit harmful code or malware</li>
        </ul>
      </section>

      <section>
        <h2>6. Intellectual Property</h2>
        <p>The Platform and its original content, features, and functionality are owned by HeadingToMars.com and are protected by international copyright, trademark, and other intellectual property laws.</p>
      </section>

      <section>
        <h2>7. Data Privacy</h2>
        <p>Your use of the Platform is also governed by our Privacy Policy. Please review our Privacy Policy to understand how we collect, use, and share your information.</p>
      </section>

      <section>
        <h2>8. Subscription and Payments</h2>
        <p>Some features of our Platform require a paid subscription. By subscribing to our services, you agree to:</p>
        <ul>
          <li>Provide accurate billing information</li>
          <li>Pay all charges at the prices in effect when incurred</li>
          <li>Pay any applicable taxes</li>
        </ul>
      </section>

      <section>
        <h2>9. Termination</h2>
        <p>We may terminate or suspend your account and access to the Platform immediately, without prior notice, for any breach of these Terms of Service.</p>
      </section>

      <section>
        <h2>10. Changes to Terms</h2>
        <p>We reserve the right to modify these terms at any time. We will notify users of any material changes via email or through the Platform.</p>
      </section>

      <section>
        <h2>11. Contact Information</h2>
        <p>For any questions about these Terms of Service, please contact us at:</p>
        <p>Email: support@headingtomars.com</p>
        <p>Address: 123 Innovation Drive, San Francisco, CA 94105</p>
      </section>
    </div>
  </div>
</template>

<style scoped>
.terms-page {
  background-color: #f8f9fa;
  min-height: 100vh;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  background-color: #111827;
  z-index: 1000;
}

.nav-brand .logo {
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 1.5rem;
}

.nav-links {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.nav-link {
  text-decoration: none;
  color: white;
  transition: color 0.3s;
}

.login-btn {
  padding: 0.5rem 1.5rem;
  background-color: transparent;
  border: 2px solid white;
  border-radius: 5px;
}

.login-btn:hover {
  background-color: white;
  color: #111827;
}

.terms-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 120px 2rem 4rem;
}

h1 {
  font-size: 2.5rem;
  color: #111827;
  margin-bottom: 1rem;
}

.last-updated {
  color: #6b7280;
  margin-bottom: 3rem;
}

section {
  margin-bottom: 3rem;
}

h2 {
  color: #111827;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

p {
  color: #4b5563;
  line-height: 1.6;
  margin-bottom: 1rem;
}

ul {
  list-style-type: disc;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
}

li {
  color: #4b5563;
  line-height: 1.6;
  margin-bottom: 0.5rem;
}

@media (max-width: 640px) {
  .terms-container {
    padding: 100px 1rem 2rem;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.25rem;
  }
}
</style>

<script>
export default {
  name: 'TermsOfService'
}
</script>