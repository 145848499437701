<template>
  <div class="">
    <Form @submit="onSubmit" :validation-schema="schema" class="">
      <div v-if="checkQuery('trial')">
        <div class="row">
          <div class="col-md-6 ml-auto mr-auto text-center">
            <h2 class="title text-white">Please register and login first!</h2>
            <h5 class="description">
              Each package includes Free Unlimited Updates and Premium Support.
            </h5>
          </div>
        </div>
      </div>

      <div v-if="checkQuery('comment') | checkQuery('watch_stock')">
        <div class="row">
          <div class="col-md-6 ml-auto mr-auto text-center">
            <h2 class="title text-white">Please register and login first!</h2>
            <h5 class="description">
              By registering you will be able to create your portfolio and comment!
            </h5>
          </div>
        </div>
      </div>

      <div class="row ">
        <div class="col-lg-5 card-dark ml-auto"> <!-- Stock Tracking Section -->
          <div class="info-area info-horizontal mt-5">
            <div class="icon icon-primary">
              <i class="now-ui-icons media-2_sound-wave"></i>
            </div>
            <div class="description">
              <h3>Join Our Referral Program!</h3>

              <p class="description">
                Share your unique referral code with friends and earn rewards for every successful signup!
              </p>

              <hr style="border-top: 1px solid #555;">
              <h3>Referral Policy</h3>
              <p class="policy">
                <i class="fal fa-gift"></i> Get <strong>a free month</strong> for each successful referral.<br><br>

                <i class="fal fa-coin"></i> Earn a <strong>20% commission</strong> on all sales made by referred
                customers for the first 12
                months.<br>
              </p>
              <hr style="border-top: 1px solid #555;">
              <p class="policy">

                <i class="fal fa-money-bill-wave-alt"></i> Receive a <strong>€50 bonus</strong> for every 5 successful
                referrals after 3 months of usage.<br><br>

                <i class="fal fa-gem"></i> For high-ticket items, earn a <strong>15% commission</strong> and a
                <strong>€500 bonus</strong> after 3
                large sales.
              </p>

              <div v-if="this.user.share_referral_code">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <n-button type="primary" @click="shareReferralCode" round size="lg">
                          <i class="fal fa-external-link"></i> Share referral link
                        </n-button>
                      </td>
                      <td>
                        <n-button type="info" @click="copyReferralCode" round size="lg">
                          <i class="fal fa-copy"></i> Copy your referral code
                        </n-button>
                        <small v-if="copySuccess" class="text-success">{{ copySuccess }}</small>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else>
                <hr style="border-top: 1px solid #fff;">
                <p class="description">
                  <router-link class="link footer-link" to="/register">
                    <n-button type="info" round size="lg">
                      <i class='fa fa-link'></i> Please login or register to become an affiliate
                    </n-button>
                  </router-link>
                </p>
              </div>

              <p class="description" v-if="user.share_referral_code">
                <strong>Referral Code:</strong> {{ user.share_referral_code }}
              </p>

            </div>
          </div>
        </div>

        <div class="col-lg-4 mr-auto local_margin">

        </div>
      </div>
    </Form>
  </div>
</template>

<script>
import globalConfig from "@/globalConfig.js";
import utils from "@/globalUtil.js";
import { ref, getCurrentInstance } from "vue";


import { Checkbox, VeeInput } from "@/components";
import { Form } from "vee-validate";
import * as Yup from "yup";
import { mapState, mapActions } from "vuex";

export default {
  name: "vnud-register",
  components: {
    Checkbox,
    VeeInput,
    Form,
  },
  data() {
    return {
      errorMessage: this.$route.query.msg || null,
      copySuccess: "",
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      logged_in: (state) => state.logged_in,
    }),
  },
  methods: {

    checkQuery(my_string) {
      let check = this.$route.query[my_string] != null;
      console.log(" Is trial " + check);
      return check;
    },
    fallbackCopy() {
      const tempInput = document.createElement("input");
      tempInput.value = this.user.share_referral_code;
      document.body.appendChild(tempInput);
      tempInput.select();
      try {
        document.execCommand("copy");
        this.copySuccess = "Referral code copied!";
      } catch (err) {
        this.copySuccess = "Copy failed. Please try manually.";
      }
      document.body.removeChild(tempInput);
      setTimeout(() => (this.copySuccess = ""), 3000);
    },
    shareReferralCode() {
      let referralLink = `${window.location.origin}/register?ref=${this.user.share_referral_code}`;
      if (!this.user.username.includes("@"))
        referralLink += `&user_ref=${this.user.username}`

      if (navigator.share) {
        navigator.share({
          title: "HEADMARS.com Join the best stock market news platform!",
          text: "Beyond the Moon! Join HEADINGTOMARS.com for Cutting-Edge Stock Market News and Analysis.",
          url: referralLink,
        });
      } else {
        this.copySuccess = "Share feature not supported on this browser.";
        window.open(referralLink, "_blank", "noopener,noreferrer");
      }
    },
    copyReferralCode() {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(this.user.share_referral_code)
          .then(() => {
            this.copySuccess = "Referral code copied!";
            setTimeout(() => (this.copySuccess = ""), 3000);
          })
          .catch(() => {
            this.copySuccess = "Failed to copy. Please try again.";
            setTimeout(() => (this.copySuccess = ""), 3000);
          });
      } else {
        // Fallback for unsupported browsers
        this.fallbackCopy();
      }
    },
  },
  mounted() {
  },
  setup() {
    const { proxy } = getCurrentInstance();

    function onSubmit(values) {
      fetch(globalConfig.getApiPath("/user/create"), {
        method: "post",
        mode: "cors",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: values.email,
          password: values.password,
          username: values.username,
          first_name: values.firstName,
          last_name: values.lastName,
          referral: values.referral,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          debugger;
          if (data.status != "success") {
            utils.runToast(
              ` ${data.error_msg} `,
              "now-ui-icons ui-1_bell-53",
              "bottom-right",
              "danger"
            );
            return;
          }

          localStorage.setItem("userToken", data.token);
          if (data.current_user)
            localStorage.setItem("userName", data.current_user);

          proxy.$store.dispatch("fetchUser");
          proxy.$router.push("/dashboard");
        })
        .catch((error) => {
          debugger;
          console.log(error);
          utils.runToast(
            ` There was a problem accessing this server, please try again later `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        });
    }

    const schema = Yup.object().shape({
      firstName: Yup.string().required().label("The First Name"),
      lastName: Yup.string().required().label("The Last Name"),
      email: Yup.string().email().required().label("The Email"),
      username: Yup.string().min(4).required().label("Username"),
      password: Yup.string().min(6).required().label("The Password"),
    });

    return {
      onSubmit,
      schema,
    };
  },
};
</script>
<style scoped>
.local_margin {
  margin: 15px;
}

.black_color {
  color: black !important;
}

</style>
