<template>
  <div class="">
    <nav class="navbar">
      <div class="nav-brand">
              <a class="navbar-brand" href="/" style="margin-left: 5px; max-width:175px;">
            <div class="logo-tothemoon noselect">
            <div class="logo-small-text">HEADING TO</div>
            <div class="logo-large-text"><i class='fal fa-rocket fa-xs'></i> MARS</div>
            <div class="logo-small-life">.COM</div>
            </div>
        </a>
      </div>
      <div class="nav-links">
        <router-link to="/contact" class="nav-link">Contact</router-link>
        <router-link to="/login" class="nav-link login-btn">Login</router-link>
      </div>
    </nav>
    <div class="privacy-container">
      <h1>Privacy Policy</h1>
      <p class="last-updated">Last updated: March 2024</p>

      <section>
        <h2>1. Introduction</h2>
        <p>At HeadingToMars.com, we take your privacy seriously. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our platform.</p>
      </section>

      <section>
        <h2>2. Information We Collect</h2>
        <h3>2.1 Personal Information</h3>
        <p>We collect information that you provide directly to us, including:</p>
        <ul>
          <li>Name and contact information</li>
          <li>Account credentials</li>
          <li>Financial information</li>
          <li>Portfolio data</li>
          <li>Communication preferences</li>
        </ul>

        <h3>2.2 Automatically Collected Information</h3>
        <p>When you use our platform, we automatically collect:</p>
        <ul>
          <li>Device information</li>
          <li>Log data</li>
          <li>Usage information</li>
          <li>Location data</li>
          <li>Cookies and similar technologies</li>
        </ul>
      </section>

      <section>
        <h2>3. How We Use Your Information</h2>
        <p>We use the collected information for:</p>
        <ul>
          <li>Providing and improving our services</li>
          <li>Personalizing your experience</li>
          <li>Processing your transactions</li>
          <li>Communicating with you</li>
          <li>Analytics and research</li>
          <li>Security and fraud prevention</li>
        </ul>
      </section>

      <section>
        <h2>4. Information Sharing</h2>
        <p>We may share your information with:</p>
        <ul>
          <li>Service providers and partners</li>
          <li>Legal authorities when required</li>
          <li>Other users (only with your consent)</li>
          <li>Business transfers in case of sale or merger</li>
        </ul>
      </section>

      <section>
        <h2>5. Data Security</h2>
        <p>We implement appropriate technical and organizational measures to protect your information, including:</p>
        <ul>
          <li>Encryption of sensitive data</li>
          <li>Regular security assessments</li>
          <li>Access controls</li>
          <li>Secure data storage</li>
        </ul>
      </section>

      <section>
        <h2>6. Your Rights</h2>
        <p>You have the right to:</p>
        <ul>
          <li>Access your personal information</li>
          <li>Correct inaccurate data</li>
          <li>Request deletion of your data</li>
          <li>Object to processing</li>
          <li>Data portability</li>
          <li>Withdraw consent</li>
        </ul>
      </section>

      <section>
        <h2>7. Children's Privacy</h2>
        <p>Our services are not intended for users under 18 years of age. We do not knowingly collect information from children.</p>
      </section>

      <section>
        <h2>8. International Data Transfers</h2>
        <p>Your information may be transferred and processed in countries other than your own. We ensure appropriate safeguards are in place for such transfers.</p>
      </section>

      <section>
        <h2>9. Changes to Privacy Policy</h2>
        <p>We may update this Privacy Policy periodically. We will notify you of any material changes through our platform or via email.</p>
      </section>

      <section>
        <h2>10. Contact Us</h2>
        <p>If you have questions about this Privacy Policy or our practices, please contact us at:</p>
        <p>Email: privacy@headmars.com</p>

      </section>
    </div>
  </div>
</template>

<style scoped>
.privacy-page {
  background-color: #f8f9fa;
  min-height: 100vh;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  background-color: #111827;
  z-index: 1000;
}

.nav-brand .logo {
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 1.5rem;
}

.nav-links {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.nav-link {
  text-decoration: none;
  color: white;
  transition: color 0.3s;
}

.login-btn {
  padding: 0.5rem 1.5rem;
  background-color: transparent;
  border: 2px solid white;
  border-radius: 5px;
}

.login-btn:hover {
  background-color: white;
  color: #111827;
}

.privacy-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 120px 2rem 4rem;
}

h1 {
  font-size: 2.5rem;
  color: #111827;
  margin-bottom: 1rem;
}

.last-updated {
  color: #6b7280;
  margin-bottom: 3rem;
}

section {
  margin-bottom: 3rem;
}

h2 {
  color: #111827;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

h3 {
  color: #111827;
  font-size: 1.2rem;
  margin: 1.5rem 0 1rem;
}

p {
  color: #4b5563;
  line-height: 1.6;
  margin-bottom: 1rem;
}

ul {
  list-style-type: disc;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
}

li {
  color: #4b5563;
  line-height: 1.6;
  margin-bottom: 0.5rem;
}

@media (max-width: 640px) {
  .privacy-container {
    padding: 100px 1rem 2rem;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.25rem;
  }

  h3 {
    font-size: 1.1rem;
  }
}
</style>

<script>
export default {
  name: 'PrivacyPolicy'
}
</script>