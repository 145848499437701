<template>
  <div>
    <div v-if="isDev" class="debug-panel">
      <div class="row">
        <div class="col-md-6">
          <p>Route: {{ $route.name }}</p>
          <p>localStorage: {{ hasLocalStorage }}</p>
          <p>Cookie: {{ hasCookie }}</p>
          <p>User Agent: {{ userAgent }}</p>
        </div>

        <div class="col-md-6">
          <p>hasSeenLanding: {{ hasSeenLanding }}</p>
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import { watch } from "vue";

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();

    // Watch for route changes to update meta tags dynamically
    watch(
      () => route.meta,
      (meta) => {
        if (meta) {
          // Update title
          if (meta.title) {
            document.title = meta.title;
          }
          // Update meta description
          const descriptionTag = document.querySelector('meta[name="description"]');
          if (descriptionTag) {
            descriptionTag.setAttribute("content", meta.description || "");
          }

          // Update canonical link
          const canonicalTag = document.querySelector('link[rel="canonical"]');
          if (canonicalTag) {
            canonicalTag.setAttribute("href", meta.canonical || window.location.href);
          } else {
            // Create a canonical tag if it doesn't exist
            const link = document.createElement("link");
            link.setAttribute("rel", "canonical");
            link.setAttribute("href", meta.canonical || window.location.href);
            document.head.appendChild(link);
          }
        }
      },
      { immediate: true }
    );
  },
  data() {
    return {
      isDev: process.env.NODE_ENV === "development",
      hasLocalStorage: null,
      hasCookie: null,
      userAgent: navigator.userAgent,
    };
  },
  created() {
    this.checkStorage();
  },
  methods: {
    checkStorage() {
      try {
        this.hasLocalStorage = localStorage.getItem("hasSeenLanding");
        this.hasCookie = document.cookie.includes("hasSeenLanding");
      } catch (e) {
        console.error("Storage check error:", e);
      }
    },
  },
};
</script>

<style>
.debug-panel {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px;
  font-size: 12px;
  z-index: 9999;
}
</style>
