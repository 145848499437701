<template>
  <div class="row">
    <!-- <PleaseLogin /> -->
    <NewsTimeline :key="$route.fullPath" :apicall="apicall" disable_load></NewsTimeline>
  </div>

  <div v-if="show_chat_panel"> 
    <AIPromptChat title="Q&A" :article_id="$route.query.id"/>
  </div>
</template>
<style>
.ticker {
  text-transform: uppercase;
}
</style>
<script>
import globalController from "@/globalController.js";
import utils from "@/globalUtil.js";

import NewsTimeline from "@/views/dashboard/news/NewsTimeline.vue";
import PleaseLogin from "@/components/PleaseLogin.vue";
import { boolean } from "yup";
import AIPromptChat from "@/views/dashboard/pages/AIPromptChat.vue";

export default {
  components: { PleaseLogin, NewsTimeline, AIPromptChat },
  created() {

  },
  props: {
    apicall: String,
    show_chat_panel: Boolean,
    disable_load: Boolean,
  },
  setLoading() { },
  data() {
    return {
      isLoaded: false,
    };
  },
  watch: {
    "$route.query": {
      handler(newQuery, oldQuery) {

      },
      deep: true, // Allows deep watching of nested properties
      immediate: true, // If you want the handler to be called immediately
    },
  },
};
</script>
<style></style>
