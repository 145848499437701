import * as Vue from 'vue'
import VueObserveVisibility from 'vue-observe-visibility'

if (process.env.NODE_ENV === 'development') {
    console.log(" NODE_ENV " + process.env.NODE_ENV);
}

import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import store from "./globalStore.js";

import DashboardPlugin from "./dashboard-plugin";
import CanvasJSStockChart from '@canvasjs/vue-stockcharts';
import 'animate.css'

const options = { containerClassName: "ct-notification" };

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(Toast, options);
appInstance.use(CanvasJSStockChart);
appInstance.use(VueObserveVisibility);

appInstance.use(DashboardPlugin);
appInstance.mount("#app");

window.sitemap = function () {
    const routes = router
        .getRoutes()
        .map(r => r.path)
        .filter(r => !r.includes(':')) // removes routes with params
        .map(r => `<url><loc>https://headingtomars.com${r}</loc></url>`)

    const sitemapContent = `
        <?xml version='1.0' encoding='UTF-8'?>
        <urlset xmlns='http://www.sitemaps.org/schemas/sitemap/0.9'>
            ${routes.join('\n')}
        </urlset>
      `;

    // Create a Blob with the sitemap content
    const blob = new Blob([sitemapContent], { type: 'application/xml' });
    const url = URL.createObjectURL(blob);

    // Create a temporary anchor element to trigger download
    const link = document.createElement('a');
    link.href = url;
    link.download = 'sitemap.xml'; // Suggested filename
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
}

window.generateRobotsTxt = function () {
    const robotsContent = `
        User-agent: *
        Disallow: /admin/
        Disallow: /login/
        Allow: /
        Sitemap: https://headingtomars.com/sitemap.xml
    `.trim();

    // Create a Blob with the robots.txt content
    const blob = new Blob([robotsContent], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);

    // Create a temporary anchor element to trigger download
    const link = document.createElement('a');
    link.href = url;
    link.download = 'robots.txt'; // Suggested filename
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
};
