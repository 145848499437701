<template>
  <div class="landing-page">
    <div class="hero-container">
      <nav class="navbar">

        <a class="navbar-brand" href="/" style="margin-left: 5px; max-width:175px;">
            <div class="logo-tothemoon noselect">
            <div class="logo-small-text">HEADING TO</div>
            <div class="logo-large-text"><i class='fal fa-rocket fa-xs'></i> MARS</div>
            <div class="logo-small-life">.COM</div>
            </div>
        </a>

        <div class="nav-links">
          <router-link to="/contact" class="nav-link">Contact</router-link>
          <router-link to="/login" class="nav-link login-btn">Login</router-link>
        </div>
      </nav>

      <div class="hero-section">
        <img src="/citygirl/citygirl.png" alt="Welcome" class="hero-image">
        <div class="hero-content animate__animated animate__fadeInLeft">
          <h4><span class="highlight">Secure AI Investing</span></h4>
          <h1>Your Edge in a <br>$100 Trillion Market</h1>
          <p class="hero-text">
            Stop gambling with your wealth. Join elite investors who use our institutional-grade AI to analyze 100,000+ data points per second, identifying opportunities that others miss. Our users average 31% better returns than self-directed investors.*
          </p>
          <div class="cta-group">
            <router-link to="/analysis/latest" class="primary-btn">
              Gain Your Edge Now
            </router-link>
            <router-link to="/analysis/latest" class="secondary-btn">
              See How It Works
            </router-link>
          </div>
          <div class="subscribe-inline animate__animated animate__fadeInUp animate__delay-1s">
            <form @submit.prevent="handleSubscribe" class="subscribe-form-inline">
              <input
                type="email"
                v-model="emailSubscribe"
                placeholder="Enter your email for market insights"
                required
              >
              <button type="submit" class="subscribe-btn-inline">
                <span v-if="!subscribing">Get Insights</span>
                <span v-else>Subscribing...</span>
              </button>
            </form>
            <p class="subscribe-note-inline">Join 12,000+ investors receiving our weekly insights</p>
          </div>
          <div class="trust-badges">
            <div class="badge">
              <span class="number">$2.1B+</span>
              <span class="label">Assets Analyzed</span>
            </div>
            <div class="badge">
              <span class="number">12,405</span>
              <span class="label">Active Investors</span>
            </div>
            <div class="badge">
              <span class="number">31%</span>
              <span class="label">Better Returns*</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="content-sections">
      <!-- Section 1 -->
      <div class="section">
        <div
          class="section-content animate__animated"
          :class="{'animate__fadeIn': isVisible.section1}"
          data-section="1"
        >
          <h2>Transform Your Investment Strategy with AI</h2>
          <h3>From Market Uncertainty to Data-Driven Confidence</h3>
          <p>Stop losing money on gut feelings. Our AI analyzes millions of data points to give you institutional-grade insights, helping you make decisions like top 1% investors. Join thousands of traders who've increased their returns by an average of 23%.*</p>
          <router-link to="/" class="learn-more">Start Your Transformation</router-link>
        </div>
      </div>

      <!-- Section 2 -->
      <div class="section">
        <div
          class="section-content animate__animated"
          :class="{'animate__fadeIn': isVisible.section2}"
          data-section="2"
        >
          <h2>Your 24/7 Financial Analysis Team</h2>
          <p>Get instant access to what would cost $500,000+ in analyst salaries. Our AI performs real-time DCF analysis, valuation modeling, and predictive analytics that typically requires an entire team. Make institutional-quality decisions at a fraction of the cost.</p>
          <router-link to="/" class="learn-more">Access Pro-Level Analysis</router-link>
        </div>
      </div>

      <!-- Section 3 -->
      <div class="section">
        <div
          class="section-content animate__animated"
          :class="{'animate__fadeIn': isVisible.section3}"
          data-section="3"
        >
          <h2>Find Hidden Market Opportunities</h2>
          <p>While others rely on outdated news, our AI searches and analyzes 100,000+ financial data points per second. Discover undervalued stocks, predict market trends, and spot opportunities others miss. Get the same edge that hedge funds pay millions for.</p>
          <router-link to="/" class="learn-more">Unlock Market Insights</router-link>
        </div>
      </div>

      <!-- Section 4 -->
      <!--
      <div class="section">
        <div class="section-content animate__animated animate__fadeIn">
          <h2>Universal Brokerage Integration</h2>
          <p>Stay ahead with our Universal Brokerage Integrations, empowering you to monitor and optimize your portfolio of stocks, crypto and more all in one place</p>
          <button class="portfolio-link">Link My Portfolio</button>
        </div>
      </div>
      -->
    </div>

    <!-- Add disclaimer at the bottom -->

    <footer class="footer">
      <div class="footer-content">
        <div class="footer-section">
          <h3>HeadMars</h3>
          <p>Revolutionizing investment strategies with AI-powered analytics.<br><br>  Helping you navigate the financial universe with precision and confidence.</p>
          <div class="social-links">
            <a href="#" aria-label="Twitter"><i class="fab fa-twitter"></i></a>
            <a href="#" aria-label="LinkedIn"><i class="fab fa-linkedin"></i></a>
            <a href="#" aria-label="Instagram"><i class="fab fa-instagram"></i></a>
          </div>
        </div>

        <div class="footer-section">
          <h3>Quick Links</h3>
          <ul>
            <li><router-link to="/about">About</router-link></li>
            <li><router-link to="/features">Features</router-link></li>
            <li><router-link to="/pricing">Pricing</router-link></li>
            <li><router-link to="/blog">Blog</router-link></li>
          </ul>
        </div>

        <div class="footer-section">
          <h3>Support</h3>
          <ul>
            <li><router-link to="/help">Help Center</router-link></li>
            <li><router-link to="/contact">Contact Us</router-link></li>
            <li><router-link to="/faq">FAQ</router-link></li>
            <li><router-link to="/documentation">Documentation</router-link></li>
          </ul>
        </div>

        <div class="footer-section">
          <h3>Contact</h3>
          <p>Email: info@headmars.com</p>
          <p>Phone: +44 75 175 16 176</p>
          <p>Address: 30 Churchill Place, Canary Wharf, London, E14 5RE</p>
        </div>
      </div>

      <div class="footer-bottom">
        <div class="footer-legal">
          <router-link to="/privacy">Privacy Policy</router-link>
          <router-link to="/terms">Terms of Service</router-link>
          <router-link to="/cookies">Cookie Policy</router-link>
        </div>
        <div class="copyright">
          <p>&copy; 2024 HeadMars. All rights reserved.</p>
        </div>
      </div>
    </footer>
  </div>
</template>

<style scoped>
.landing-page {
  width: 100%;
  overflow-x: hidden;
}

.hero-container {
  position: relative;
  width: 100%;
  height: 100vh;
}

.navbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;

  z-index: 1000;
}

.nav-brand {
  font-size: 1.5rem;
  font-weight: bold;
}

.logo {
  text-decoration: none;
  color: white;
  font-weight: bold;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

.nav-links {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.nav-link {
  text-decoration: none;
  color: white;
  font-weight: 500;
  transition: color 0.3s;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

.nav-link:hover {
  color: rgba(255, 255, 255, 0.8);
}

.login-btn {
  padding: 0.5rem 1.5rem;
  background-color: transparent;
  color: white;
  border: 2px solid white;
  border-radius: 5px;
  transition: all 0.3s;
}

.login-btn:hover {
  background-color: white;
  color: #333;
  text-shadow: none;
}

.hero-section {
  position: relative;
  width: 100%;
  height: 100vh;
}

.hero-section::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust opacity as needed */
  z-index: 1;
}

.hero-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-content {
  position: absolute;
  top: 20%;
  left: 10%;
  transform: translateY(-50%);
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  max-width: 800px;
  z-index: 2;
}

.hero-content h1 {
  font-size: 4rem;
  margin-bottom: 1.5rem;
  font-weight: bold;
}

.hero-content h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  line-height: 1.2;
}

.hero-text {
  font-size: 1.25rem;
  line-height: 1.6;
  margin: 1.5rem 0;
  max-width: 600px;
  color: #E5E7EB;
}

.trust-badges {
  display: flex;
  gap: 3rem;
  margin-top: 3rem;
}

.badge {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.30rem;
  padding: 1rem;
}

.badge .number {
  font-size: 1.75rem;
  font-weight: bold;
  color: #60A5FA;
  line-height: 1;
}

.badge .label {
  font-size: 0.875rem;
  color: #9CA3AF;
  text-align: center;
  line-height: 1.4;
}

.cta-group {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  width: 100%;
}

.primary-btn {
  background: linear-gradient(135deg, #3B82F6 0%, #2563EB 100%);
  color: white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 16px;
  font-weight: 600;
  font-size: 1.125rem;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

.primary-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(37, 99, 235, 0.3);
}

.secondary-btn {
  background: transparent;
  color: white;
  padding: 1rem 2rem;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  font-weight: 600;
  font-size: 1.125rem;
  cursor: pointer;
  transition: border-color 0.2s, background 0.2s;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

.secondary-btn:hover {
  border-color: rgba(255, 255, 255, 0.4);
  background: rgba(255, 255, 255, 0.1);
}

.content-sections {
  padding: 4rem 0;
}

.section {
  padding: 6rem 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section:nth-child(even) {
  background-color: #f8f9fa;
}

.section-content {
  max-width: 800px;
  text-align: center;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-out, transform 1s ease-out;
  min-height: 200px; /* Ensure sections have height */
  padding: 2rem;
  margin: 2rem 0;
}

.section-content.animate__fadeIn {
  opacity: 1;
  transform: translateY(0);
}

.section-content h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #333;
}

.section-content h3 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #666;
}

.section-content p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #666;
  margin-bottom: 2rem;
}

.learn-more {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: 500;
  transition: background-color 0.3s;
  border: none;
  cursor: pointer;
  margin-top: 1rem;
}

.learn-more:hover {
  background-color: #0056b3;
}

/* Add animation classes for scroll reveal */
.animate__animated {
  --animate-duration: 1s;
}

.animate__fadeIn {
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Add animation delay for sections */
.section:nth-child(2) .animate__animated {
  --animate-delay: 0.2s;
}

.section:nth-child(3) .animate__animated {
  --animate-delay: 0.4s;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .hero-content {
    left: 5%;
    right: 5%;
    max-width: 90%;
  }

  .hero-content h1 {
    font-size: 2.8rem;
  }

  .hero-content h2 {
    font-size: 2rem;
  }

  .section {
    padding: 4rem 5%;
  }

  .navbar {
    padding: 0 1rem;
  }

  .nav-links {
    gap: 1rem;
  }

  .trust-badges {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    margin-top: 2.5rem;
  }

  .cta-group {
    flex-direction: column;
    width: 100%;
    gap: 1rem;
  }

  .primary-btn,
  .secondary-btn {
    width: 100%;
    padding: 1rem;
    font-size: 1rem;
    display: block;
    touch-action: manipulation;
  }

  .hero-text {
    font-size: 1.125rem;
  }

  .badge {
    gap: 0.5rem;
  }
}

/* Active states for mobile */
.primary-btn:active {
  transform: scale(0.98);
  background: linear-gradient(135deg, #2563EB 0%, #1D4ED8 100%);
}

.secondary-btn:active {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.3);
}

.footer {
  background-color: #111827;
  color: #fff;
  padding: 4rem 2rem 2rem;
  margin-top: 4rem;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3rem;
}

.footer-section h3 {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.footer-section p {
  color: #9ca3af;
  line-height: 1.6;
  margin-bottom: 1rem;
  font-size: 0.95rem;
}

.footer-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-section ul li {
  margin-bottom: 0.75rem;
}

.footer-section ul li a {
  color: #9ca3af;
  text-decoration: none;
  transition: color 0.2s;
  font-size: 0.95rem;
}

.footer-section ul li a:hover {
  color: #fff;
}

.social-links {
  display: flex;
  gap: 1.5rem;
  margin-top: 1.5rem;
}

.social-links a {
  color: #9ca3af;
  font-size: 1.5rem;
  transition: color 0.2s;
}

.social-links a:hover {
  color: #fff;
}

.footer-bottom {
  max-width: 1200px;
  margin: 3rem auto 0;
  padding-top: 2rem;
  border-top: 1px solid #374151;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-legal {
  display: flex;
  gap: 2rem;
}

.footer-legal a {
  color: #9ca3af;
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.2s;
}

.footer-legal a:hover {
  color: #fff;
}

.copyright {
  color: #9ca3af;
  font-size: 0.9rem;
}

@media (max-width: 1024px) {
  .footer-content {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
}

@media (max-width: 640px) {
  .footer-content {
    grid-template-columns: 1fr;
  }

  .footer-bottom {
    flex-direction: column;
    gap: 1.5rem;
    text-align: center;
  }

  .footer-legal {
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }
}

/* Add some spacing between sections for better visibility */
.section {
  margin: 100px 0;
  min-height: 300px;
}

/* Debug styles to see section boundaries */
.section {
  border: 1px solid transparent; /* Helps with intersection detection */
  margin: 50px 0;
  min-height: 300px;
}

/* Make sure animations work */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate__fadeIn {
  animation: fadeIn 1s ease-out forwards;
}

.subscribe-inline {
  margin-top: 2rem;
  width: 100%;
  max-width: 600px;
}

.subscribe-form-inline {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.subscribe-form-inline input {
  flex: 1;
  padding: 0.75rem 1rem;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  font-size: 1rem;
}

.subscribe-form-inline input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.subscribe-form-inline input:focus {
  outline: none;
  border-color: rgba(255, 255, 255, 0.4);
  background: rgba(255, 255, 255, 0.15);
}

.subscribe-btn-inline {
  padding: 0.75rem 1.5rem;
  background: rgba(255, 255, 255, 0.9);
  color: #1E3A8A;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
  white-space: nowrap;
}

.subscribe-btn-inline:hover {
  background: white;
  transform: translateY(-1px);
}

.subscribe-note-inline {
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.7);
  margin: 0.5rem 0;
}

@media (max-width: 640px) {
  .subscribe-form-inline {
    flex-direction: column;
  }

  .subscribe-btn-inline {
    width: 100%;
  }
}

.animate__fadeInUp {
  animation-name: fadeInUp;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animate__delay-1s {
  animation-delay: 1s;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
</style>

<script>
import 'animate.css'
import globalController from "@/globalController.js";
import utils from "@/globalUtil.js";

export default {
  name: 'LandingPage',
  data() {
    return {
      isVisible: {
        section1: false,
        section2: false,
        section3: false
      },
      emailSubscribe: '',
      subscribing: false
    }
  },
  mounted() {
    this.setLandingSeen()
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          // Get section number from data attribute
          const sectionNumber = entry.target.dataset.section
          if (sectionNumber) {
            this.isVisible[`section${sectionNumber}`] = entry.isIntersecting
            if (entry.isIntersecting) {
              console.log(`Section ${sectionNumber} is now visible`)
            }
          }
        })
      },
      {
        threshold: 0.2,
        rootMargin: '0px'
      }
    );

    // Observe all sections
    document.querySelectorAll('.section-content').forEach((el, index) => {
      el.dataset.section = index + 1
      observer.observe(el)
    });
  },
  methods: {
    setLandingSeen() {
      try {
        // Try localStorage first
        localStorage.setItem('hasSeenLanding', 'true')
      } catch (e) {
        console.log('localStorage failed, falling back to cookie')
      }
      // Always set cookie as backup
      this.setCookie('hasSeenLanding', 'true', 30)
    },
    setCookie(name, value, days) {
      const date = new Date()
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
      const expires = "; expires=" + date.toUTCString()
      document.cookie = name + "=" + value + expires + "; path=/; SameSite=Lax"
      console.log('Cookie set:', name, value)
    },
    navigateTo(path) {
      // Ensure landing is marked as seen before navigation
      this.setLandingSeen()
      this.$router.push(path)
    },
    scrollToFeatures() {
      const featuresSection = document.querySelector('.section')
      if (featuresSection) {
        featuresSection.scrollIntoView({ behavior: 'smooth' })
      }
    },
    async handleSubscribe() {
      if (!this.emailSubscribe) return;

      this.subscribing = true;
      globalController.subscribe_email(
        this.emailSubscribe,
        (data) => {
          this.emailSubscribe = '';
          utils.runToast("Thank you for subscribing!", "now-ui-icons ui-1_bell-53", 'bottom-right', 'success');
          this.subscribing = false;
        },
        (error) => {
          utils.runToast(error || "Sorry, there was an error. Please try again.", "now-ui-icons ui-1_bell-53", 'bottom-right', 'danger');
          this.subscribing = false;
        }
      );
    }
  }
}
</script>