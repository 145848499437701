<template>
    <span>Cookie Policy</span>
  <div class="">
    <nav class="navbar">
      <div class="nav-brand">
        <a class="navbar-brand" href="/" style="margin-left: 5px; max-width:175px;">
            <div class="logo-tothemoon noselect">
            <div class="logo-small-text">HEADING TO</div>
            <div class="logo-large-text"><i class='fal fa-rocket fa-xs'></i> MARS</div>
            <div class="logo-small-life">.COM</div>
            </div>
        </a>
      </div>
      <div class="nav-links">
        <router-link to="/contact" class="nav-link">Contact</router-link>
        <router-link to="/login" class="nav-link login-btn">Login</router-link>
      </div>
    </nav>

    <div class="policy-container">
      <h1>Cookie Policy</h1>
      <p class="last-updated">Last updated: March 2024</p>

      <section>
        <h2>1. Introduction</h2>
        <p>HeadingToMars.com ("we" or "us" or "our") uses cookies on our website. This policy explains how we use cookies and similar technologies to recognize you when you visit our website.</p>
      </section>

      <section>
        <h2>2. What are cookies?</h2>
        <p>Cookies are small data files that are placed on your computer or mobile device when you visit a website. They are widely used to make websites work more efficiently and provide reporting information.</p>
      </section>

      <section>
        <h2>3. How we use cookies</h2>
        <p>We use cookies for the following purposes:</p>
        <ul>
          <li><strong>Essential cookies:</strong> Required for the operation of our website, including keeping you signed in and managing your session.</li>
          <li><strong>Analytics cookies:</strong> Allow us to analyze how visitors use our site to improve its performance and functionality.</li>
          <li><strong>Preference cookies:</strong> Enable our website to remember your preferences and personalized settings.</li>
          <li><strong>Marketing cookies:</strong> Used to track visitors across websites to display relevant advertisements.</li>
        </ul>
      </section>

      <section>
        <h2>4. Specific cookies we use</h2>
        <div class="cookie-table">
          <table>
            <thead>
              <tr>
                <th>Cookie Name</th>
                <th>Purpose</th>
                <th>Duration</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>session_id</td>
                <td>Maintains your session while logged in</td>
                <td>Session</td>
              </tr>
              <tr>
                <td>preferences</td>
                <td>Stores your website preferences</td>
                <td>1 year</td>
              </tr>
              <tr>
                <td>analytics</td>
                <td>Tracks anonymous usage data</td>
                <td>2 years</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <section>
        <h2>5. Managing cookies</h2>
        <p>Most web browsers allow you to control cookies through their settings preferences. However, limiting cookies may impact your experience of our website.</p>
        <p>To manage cookies in your browser:</p>
        <ul>
          <li>Chrome: Settings → Privacy and Security → Cookies</li>
          <li>Firefox: Options → Privacy & Security → Cookies</li>
          <li>Safari: Preferences → Privacy → Cookies</li>
          <li>Edge: Settings → Privacy & Security → Cookies</li>
        </ul>
      </section>

      <section>
        <h2>6. Contact us</h2>
        <p>If you have any questions about our use of cookies, please contact us at support@headingtomars.com</p>
      </section>
    </div>
  </div>
</template>

<style scoped>
.cookie-policy {
  background-color: #f8f9fa;
  min-height: 100vh;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  background-color: #111827;
  z-index: 1000;
}

.nav-brand .logo {
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 1.5rem;
}

.nav-links {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.nav-link {
  text-decoration: none;
  color: white;
  transition: color 0.3s;
}

.login-btn {
  padding: 0.5rem 1.5rem;
  background-color: transparent;
  border: 2px solid white;
  border-radius: 5px;
}

.login-btn:hover {
  background-color: white;
  color: #111827;
}

.policy-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 120px 2rem 4rem;
}

h1 {
  font-size: 2.5rem;
  color: #111827;
  margin-bottom: 1rem;
}

.last-updated {
  color: #6b7280;
  margin-bottom: 3rem;
}

section {
  margin-bottom: 3rem;
}

h2 {
  color: #111827;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

p {
  color: #4b5563;
  line-height: 1.6;
  margin-bottom: 1rem;
}

ul {
  list-style-type: disc;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
}

li {
  color: #4b5563;
  line-height: 1.6;
  margin-bottom: 0.5rem;
}

.cookie-table {
  overflow-x: auto;
  margin: 1rem 0;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
}

th, td {
  padding: 0.75rem;
  text-align: left;
  border-bottom: 1px solid #e5e7eb;
}

th {
  background-color: #f3f4f6;
  font-weight: 600;
  color: #111827;
}

td {
  color: #4b5563;
}

@media (max-width: 640px) {
  .policy-container {
    padding: 100px 1rem 2rem;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.25rem;
  }
}
</style>

<script>
export default {
  name: 'CookiePolicy',
  mounted() {
    console.log('CookiePolicy component mounted')
  }
}
</script>